/*-------------- PADDINGS --------------*/
.p-tb-200 {
  padding-top: 200px;
  padding-bottom: 200px;
}

.p-t-200 {
  padding-top: 200px;
}

.p-b-200 {
  padding-bottom: 200px;
}

@media screen and (max-width: 1200px) {
  .p-tb-200 {
    padding-top: 178px;
    padding-bottom: 178px;
  }
  .p-t-200 {
    padding-top: 178px;
  }
  .p-b-200 {
    padding-bottom: 178px;
  }
}

@media screen and (max-width: 950px) {
  .p-tb-200 {
    padding-top: 156px;
    padding-bottom: 156px;
  }
  .p-t-200 {
    padding-top: 156px;
  }
  .p-b-200 {
    padding-bottom: 156px;
  }
}

@media screen and (max-width: 700px) {
  .p-tb-200 {
    padding-top: 134px;
    padding-bottom: 134px;
  }
  .p-t-200 {
    padding-top: 134px;
  }
  .p-b-200 {
    padding-bottom: 134px;
  }
}

@media screen and (max-width: 500px) {
  .p-tb-200 {
    padding-top: 112px;
    padding-bottom: 112px;
  }
  .p-t-200 {
    padding-top: 112px;
  }
  .p-b-200 {
    padding-bottom: 112px;
  }
}

@media screen and (max-width: 400px) {
  .p-tb-200 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .p-t-200 {
    padding-top: 90px;
  }
  .p-b-200 {
    padding-bottom: 90px;
  }
}

.p-tb-160 {
  padding-top: 160px;
  padding-bottom: 160px;
}

.p-t-160 {
  padding-top: 160px;
}

.p-b-160 {
  padding-bottom: 160px;
}

@media screen and (max-width: 1200px) {
  .p-tb-160 {
    padding-top: 142px;
    padding-bottom: 142px;
  }
  .p-t-160 {
    padding-top: 142px;
  }
  .p-b-160 {
    padding-bottom: 142px;
  }
}

@media screen and (max-width: 950px) {
  .p-tb-160 {
    padding-top: 125px;
    padding-bottom: 125px;
  }
  .p-t-160 {
    padding-top: 125px;
  }
  .p-b-160 {
    padding-bottom: 125px;
  }
}

@media screen and (max-width: 700px) {
  .p-tb-160 {
    padding-top: 107px;
    padding-bottom: 107px;
  }
  .p-t-160 {
    padding-top: 107px;
  }
  .p-b-160 {
    padding-bottom: 107px;
  }
}

@media screen and (max-width: 500px) {
  .p-tb-160 {
    padding-top: 90px;
    padding-bottom: 90px;
  }
  .p-t-160 {
    padding-top: 90px;
  }
  .p-b-160 {
    padding-bottom: 90px;
  }
}

@media screen and (max-width: 400px) {
  .p-tb-160 {
    padding-top: 72px;
    padding-bottom: 72px;
  }
  .p-t-160 {
    padding-top: 72px;
  }
  .p-b-160 {
    padding-bottom: 72px;
  }
}

.p-tb-120 {
  padding-top: 120px;
  padding-bottom: 120px;
}

.p-t-120 {
  padding-top: 120px;
}

.p-b-120 {
  padding-bottom: 120px;
}

@media screen and (max-width: 1200px) {
  .p-tb-120 {
    padding-top: 107px;
    padding-bottom: 107px;
  }
  .p-t-120 {
    padding-top: 107px;
  }
  .p-b-120 {
    padding-bottom: 107px;
  }
}

@media screen and (max-width: 950px) {
  .p-tb-120 {
    padding-top: 94px;
    padding-bottom: 94px;
  }
  .p-t-120 {
    padding-top: 94px;
  }
  .p-b-120 {
    padding-bottom: 94px;
  }
}

@media screen and (max-width: 700px) {
  .p-tb-120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .p-t-120 {
    padding-top: 80px;
  }
  .p-b-120 {
    padding-bottom: 80px;
  }
}

@media screen and (max-width: 500px) {
  .p-tb-120 {
    padding-top: 67px;
    padding-bottom: 67px;
  }
  .p-t-120 {
    padding-top: 67px;
  }
  .p-b-120 {
    padding-bottom: 67px;
  }
}

@media screen and (max-width: 400px) {
  .p-tb-120 {
    padding-top: 54px;
    padding-bottom: 54px;
  }
  .p-t-120 {
    padding-top: 54px;
  }
  .p-b-120 {
    padding-bottom: 54px;
  }
}

.p-tb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.p-t-100 {
  padding-top: 100px;
}

.p-b-100 {
  padding-bottom: 100px;
}

.p-all-100 {
  padding: 100px;
}

@media screen and (max-width: 1200px) {
  .p-tb-100 {
    padding-top: 89px;
    padding-bottom: 89px;
  }
  .p-t-100 {
    padding-top: 89px;
  }
  .p-b-100 {
    padding-bottom: 89px;
  }
}

@media screen and (max-width: 950px) {
  .p-tb-100 {
    padding-top: 78px;
    padding-bottom: 78px;
  }
  .p-t-100 {
    padding-top: 78px;
  }
  .p-b-100 {
    padding-bottom: 78px;
  }
}

@media screen and (max-width: 700px) {
  .p-tb-100 {
    padding-top: 67px;
    padding-bottom: 67px;
  }
  .p-t-100 {
    padding-top: 67px;
  }
  .p-b-100 {
    padding-bottom: 67px;
  }
}

@media screen and (max-width: 500px) {
  .p-tb-100 {
    padding-top: 56px;
    padding-bottom: 56px;
  }
  .p-t-100 {
    padding-top: 56px;
  }
  .p-b-100 {
    padding-bottom: 56px;
  }
}

@media screen and (max-width: 400px) {
  .p-tb-100 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .p-t-100 {
    padding-top: 45px;
  }
  .p-b-100 {
    padding-bottom: 45px;
  }
}

.p-tb-80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.p-t-80 {
  padding-top: 80px;
}

.p-b-80 {
  padding-bottom: 80px;
}

.p-all-80 {
  padding: 80px;
}

@media screen and (max-width: 1200px) {
  .p-tb-80 {
    padding-top: 71px;
    padding-bottom: 71px;
  }
  .p-t-80 {
    padding-top: 71px;
  }
  .p-b-80 {
    padding-bottom: 71px;
  }
}

@media screen and (max-width: 950px) {
  .p-tb-80 {
    padding-top: 62px;
    padding-bottom: 62px;
  }
  .p-t-80 {
    padding-top: 62px;
  }
  .p-b-80 {
    padding-bottom: 62px;
  }
}

@media screen and (max-width: 700px) {
  .p-tb-80 {
    padding-top: 54px;
    padding-bottom: 54px;
  }
  .p-t-80 {
    padding-top: 54px;
  }
  .p-b-80 {
    padding-bottom: 54px;
  }
}

@media screen and (max-width: 500px) {
  .p-tb-80 {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .p-t-80 {
    padding-top: 45px;
  }
  .p-b-80 {
    padding-bottom: 45px;
  }
}

@media screen and (max-width: 400px) {
  .p-tb-80 {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .p-t-80 {
    padding-top: 36px;
  }
  .p-b-80 {
    padding-bottom: 36px;
  }
}

.p-tb-60 {
  padding-top: 60px;
  padding-bottom: 60px;
}

.p-t-60 {
  padding-top: 60px;
}

.p-b-60 {
  padding-bottom: 60px;
}

.p-all-60 {
  padding: 60px;
}

@media screen and (max-width: 1200px) {
  .p-tb-60 {
    padding-top: 53px;
    padding-bottom: 53px;
  }
  .p-t-60 {
    padding-top: 53px;
  }
  .p-b-60 {
    padding-bottom: 53px;
  }
}

@media screen and (max-width: 950px) {
  .p-tb-60 {
    padding-top: 47px;
    padding-bottom: 47px;
  }
  .p-t-60 {
    padding-top: 47px;
  }
  .p-b-60 {
    padding-bottom: 47px;
  }
}

@media screen and (max-width: 700px) {
  .p-tb-60 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .p-t-60 {
    padding-top: 40px;
  }
  .p-b-60 {
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 500px) {
  .p-tb-60 {
    padding-top: 34px;
    padding-bottom: 34px;
  }
  .p-t-60 {
    padding-top: 34px;
  }
  .p-b-60 {
    padding-bottom: 34px;
  }
}

@media screen and (max-width: 400px) {
  .p-tb-60 {
    padding-top: 27px;
    padding-bottom: 27px;
  }
  .p-t-60 {
    padding-top: 27px;
  }
  .p-b-60 {
    padding-bottom: 27px;
  }
}

.p-tb-40 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-all-40 {
  padding: 40px;
}

@media screen and (max-width: 1200px) {
  .p-tb-40 {
    padding-top: 36px;
    padding-bottom: 36px;
  }
  .p-t-40 {
    padding-top: 36px;
  }
  .p-b-40 {
    padding-bottom: 36px;
  }
}

@media screen and (max-width: 950px) {
  .p-tb-40 {
    padding-top: 31px;
    padding-bottom: 31px;
  }
  .p-t-40 {
    padding-top: 31px;
  }
  .p-b-40 {
    padding-bottom: 31px;
  }
}

@media screen and (max-width: 700px) {
  .p-tb-40 {
    padding-top: 27px;
    padding-bottom: 27px;
  }
  .p-t-40 {
    padding-top: 27px;
  }
  .p-b-40 {
    padding-bottom: 27px;
  }
}

@media screen and (max-width: 500px) {
  .p-tb-40 {
    padding-top: 22px;
    padding-bottom: 22px;
  }
  .p-t-40 {
    padding-top: 22px;
  }
  .p-b-40 {
    padding-bottom: 22px;
  }
}

@media screen and (max-width: 400px) {
  .p-tb-40 {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .p-t-40 {
    padding-top: 18px;
  }
  .p-b-40 {
    padding-bottom: 18px;
  }
}

.p-tb-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-all-20 {
  padding: 20px;
}

@media screen and (max-width: 1200px) {
  .p-tb-20 {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .p-t-20 {
    padding-top: 18px;
  }
  .p-b-20 {
    padding-bottom: 18px;
  }
}

@media screen and (max-width: 950px) {
  .p-tb-20 {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .p-t-20 {
    padding-top: 16px;
  }
  .p-b-20 {
    padding-bottom: 16px;
  }
}

@media screen and (max-width: 700px) {
  .p-tb-20 {
    padding-top: 13px;
    padding-bottom: 13px;
  }
  .p-t-20 {
    padding-top: 13px;
  }
  .p-b-20 {
    padding-bottom: 13px;
  }
}

@media screen and (max-width: 500px) {
  .p-tb-20 {
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .p-t-20 {
    padding-top: 11px;
  }
  .p-b-20 {
    padding-bottom: 11px;
  }
}

@media screen and (max-width: 400px) {
  .p-tb-20 {
    padding-top: 9px;
    padding-bottom: 9px;
  }
  .p-t-20 {
    padding-top: 9px;
  }
  .p-b-20 {
    padding-bottom: 9px;
  }
}

/*-------------- MARGINS --------------*/
.m-tb-200 {
  margin-top: 200px;
  margin-bottom: 200px;
}

.m-t-200 {
  margin-top: 200px;
}

.m-b-200 {
  margin-bottom: 200px;
}

@media screen and (max-width: 1200px) {
  .m-tb-200 {
    margin-top: 178px;
    margin-bottom: 178px;
  }
  .m-t-200 {
    margin-top: 178px;
  }
  .m-b-200 {
    margin-bottom: 178px;
  }
}

@media screen and (max-width: 950px) {
  .m-tb-200 {
    margin-top: 156px;
    margin-bottom: 156px;
  }
  .m-t-200 {
    margin-top: 156px;
  }
  .m-b-200 {
    margin-bottom: 156px;
  }
}

@media screen and (max-width: 700px) {
  .m-tb-200 {
    margin-top: 134px;
    margin-bottom: 134px;
  }
  .m-t-200 {
    margin-top: 134px;
  }
  .m-b-200 {
    margin-bottom: 134px;
  }
}

@media screen and (max-width: 500px) {
  .m-tb-200 {
    margin-top: 112px;
    margin-bottom: 112px;
  }
  .m-t-200 {
    margin-top: 112px;
  }
  .m-b-200 {
    margin-bottom: 112px;
  }
}

@media screen and (max-width: 400px) {
  .m-tb-200 {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .m-t-200 {
    margin-top: 90px;
  }
  .m-b-200 {
    margin-bottom: 90px;
  }
}

.m-tb-160 {
  margin-top: 160px;
  margin-bottom: 160px;
}

.m-t-160 {
  margin-top: 160px;
}

.m-b-160 {
  margin-bottom: 160px;
}

@media screen and (max-width: 1200px) {
  .m-tb-160 {
    margin-top: 142px;
    margin-bottom: 142px;
  }
  .m-t-160 {
    margin-top: 142px;
  }
  .m-b-160 {
    margin-bottom: 142px;
  }
}

@media screen and (max-width: 950px) {
  .m-tb-160 {
    margin-top: 125px;
    margin-bottom: 125px;
  }
  .m-t-160 {
    margin-top: 125px;
  }
  .m-b-160 {
    margin-bottom: 125px;
  }
}

@media screen and (max-width: 700px) {
  .m-tb-160 {
    margin-top: 107px;
    margin-bottom: 107px;
  }
  .m-t-160 {
    margin-top: 107px;
  }
  .m-b-160 {
    margin-bottom: 107px;
  }
}

@media screen and (max-width: 500px) {
  .m-tb-160 {
    margin-top: 90px;
    margin-bottom: 90px;
  }
  .m-t-160 {
    margin-top: 90px;
  }
  .m-b-160 {
    margin-bottom: 90px;
  }
}

@media screen and (max-width: 400px) {
  .m-tb-160 {
    margin-top: 72px;
    margin-bottom: 72px;
  }
  .m-t-160 {
    margin-top: 72px;
  }
  .m-b-160 {
    margin-bottom: 72px;
  }
}

.m-tb-120 {
  margin-top: 120px;
  margin-bottom: 120px;
}

.m-t-120 {
  margin-top: 120px;
}

.m-b-120 {
  margin-bottom: 120px;
}

@media screen and (max-width: 1200px) {
  .m-tb-120 {
    margin-top: 107px;
    margin-bottom: 107px;
  }
  .m-t-120 {
    margin-top: 107px;
  }
  .m-b-120 {
    margin-bottom: 107px;
  }
}

@media screen and (max-width: 950px) {
  .m-tb-120 {
    margin-top: 94px;
    margin-bottom: 94px;
  }
  .m-t-120 {
    margin-top: 94px;
  }
  .m-b-120 {
    margin-bottom: 94px;
  }
}

@media screen and (max-width: 700px) {
  .m-tb-120 {
    margin-top: 80px;
    margin-bottom: 80px;
  }
  .m-t-120 {
    margin-top: 80px;
  }
  .m-b-120 {
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 500px) {
  .m-tb-120 {
    margin-top: 67px;
    margin-bottom: 67px;
  }
  .m-t-120 {
    margin-top: 67px;
  }
  .m-b-120 {
    margin-bottom: 67px;
  }
}

@media screen and (max-width: 400px) {
  .m-tb-120 {
    margin-top: 54px;
    margin-bottom: 54px;
  }
  .m-t-120 {
    margin-top: 54px;
  }
  .m-b-120 {
    margin-bottom: 54px;
  }
}

.m-tb-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.m-t-100 {
  margin-top: 100px;
}

.m-b-100 {
  margin-bottom: 100px;
}

@media screen and (max-width: 1200px) {
  .m-tb-100 {
    margin-top: 89px;
    margin-bottom: 89px;
  }
  .m-t-100 {
    margin-top: 89px;
  }
  .m-b-100 {
    margin-bottom: 89px;
  }
}

@media screen and (max-width: 950px) {
  .m-tb-100 {
    margin-top: 78px;
    margin-bottom: 78px;
  }
  .m-t-100 {
    margin-top: 78px;
  }
  .m-b-100 {
    margin-bottom: 78px;
  }
}

@media screen and (max-width: 700px) {
  .m-tb-100 {
    margin-top: 67px;
    margin-bottom: 67px;
  }
  .m-t-100 {
    margin-top: 67px;
  }
  .m-b-100 {
    margin-bottom: 67px;
  }
}

@media screen and (max-width: 500px) {
  .m-tb-100 {
    margin-top: 56px;
    margin-bottom: 56px;
  }
  .m-t-100 {
    margin-top: 56px;
  }
  .m-b-100 {
    margin-bottom: 56px;
  }
}

@media screen and (max-width: 400px) {
  .m-tb-100 {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .m-t-100 {
    margin-top: 45px;
  }
  .m-b-100 {
    margin-bottom: 45px;
  }
}

.m-tb-80 {
  margin-top: 80px;
  margin-bottom: 80px;
}

.m-t-80 {
  margin-top: 80px;
}

.m-b-80 {
  margin-bottom: 80px;
}

@media screen and (max-width: 1200px) {
  .m-tb-80 {
    margin-top: 71px;
    margin-bottom: 71px;
  }
  .m-t-80 {
    margin-top: 71px;
  }
  .m-b-80 {
    margin-bottom: 71px;
  }
}

@media screen and (max-width: 950px) {
  .m-tb-80 {
    margin-top: 62px;
    margin-bottom: 62px;
  }
  .m-t-80 {
    margin-top: 62px;
  }
  .m-b-80 {
    margin-bottom: 62px;
  }
}

@media screen and (max-width: 700px) {
  .m-tb-80 {
    margin-top: 54px;
    margin-bottom: 54px;
  }
  .m-t-80 {
    margin-top: 54px;
  }
  .m-b-80 {
    margin-bottom: 54px;
  }
}

@media screen and (max-width: 500px) {
  .m-tb-80 {
    margin-top: 45px;
    margin-bottom: 45px;
  }
  .m-t-80 {
    margin-top: 45px;
  }
  .m-b-80 {
    margin-bottom: 45px;
  }
}

@media screen and (max-width: 400px) {
  .m-tb-80 {
    margin-top: 36px;
    margin-bottom: 36px;
  }
  .m-t-80 {
    margin-top: 36px;
  }
  .m-b-80 {
    margin-bottom: 36px;
  }
}

.m-tb-60 {
  margin-top: 60px;
  margin-bottom: 60px;
}

.m-t-60 {
  margin-top: 60px;
}

.m-b-60 {
  margin-bottom: 60px;
}

@media screen and (max-width: 1200px) {
  .m-tb-60 {
    margin-top: 53px;
    margin-bottom: 53px;
  }
  .m-t-60 {
    margin-top: 53px;
  }
  .m-b-60 {
    margin-bottom: 53px;
  }
}

@media screen and (max-width: 950px) {
  .m-tb-60 {
    margin-top: 47px;
    margin-bottom: 47px;
  }
  .m-t-60 {
    margin-top: 47px;
  }
  .m-b-60 {
    margin-bottom: 47px;
  }
}

@media screen and (max-width: 700px) {
  .m-tb-60 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  .m-t-60 {
    margin-top: 40px;
  }
  .m-b-60 {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 500px) {
  .m-tb-60 {
    margin-top: 34px;
    margin-bottom: 34px;
  }
  .m-t-60 {
    margin-top: 34px;
  }
  .m-b-60 {
    margin-bottom: 34px;
  }
}

@media screen and (max-width: 400px) {
  .m-tb-60 {
    margin-top: 27px;
    margin-bottom: 27px;
  }
  .m-t-60 {
    margin-top: 27px;
  }
  .m-b-60 {
    margin-bottom: 27px;
  }
}

.m-tb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.m-t-30 {
  margin-top: 30px;
}

.m-b-30 {
  margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
  .m-tb-30 {
    margin-top: 27px;
    margin-bottom: 27px;
  }
  .m-t-30 {
    margin-top: 27px;
  }
  .m-b-30 {
    margin-bottom: 27px;
  }
}

@media screen and (max-width: 950px) {
  .m-tb-30 {
    margin-top: 23px;
    margin-bottom: 23px;
  }
  .m-t-30 {
    margin-top: 23px;
  }
  .m-b-30 {
    margin-bottom: 23px;
  }
}

@media screen and (max-width: 700px) {
  .m-tb-30 {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .m-t-30 {
    margin-top: 20px;
  }
  .m-b-30 {
    margin-bottom: 20px;
  }
}

@media screen and (max-width: 500px) {
  .m-tb-30 {
    margin-top: 17px;
    margin-bottom: 17px;
  }
  .m-t-30 {
    margin-top: 17px;
  }
  .m-b-30 {
    margin-bottom: 17px;
  }
}

@media screen and (max-width: 400px) {
  .m-tb-30 {
    margin-top: 14px;
    margin-bottom: 14px;
  }
  .m-t-30 {
    margin-top: 14px;
  }
  .m-b-30 {
    margin-bottom: 14px;
  }
}

.m-tb-15 {
  margin-top: 15px;
  margin-bottom: 15px;
}

.m-t-15 {
  margin-top: 15px;
}

.m-b-15 {
  margin-bottom: 15px;
}

@media screen and (max-width: 1200px) {
  .m-tb-15 {
    margin-top: 13px;
    margin-bottom: 13px;
  }
  .m-t-15 {
    margin-top: 13px;
  }
  .m-b-15 {
    margin-bottom: 13px;
  }
}

@media screen and (max-width: 950px) {
  .m-tb-15 {
    margin-top: 12px;
    margin-bottom: 12px;
  }
  .m-t-15 {
    margin-top: 12px;
  }
  .m-b-15 {
    margin-bottom: 12px;
  }
}

@media screen and (max-width: 700px) {
  .m-tb-15 {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .m-t-15 {
    margin-top: 10px;
  }
  .m-b-15 {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 500px) {
  .m-tb-15 {
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .m-t-15 {
    margin-top: 8px;
  }
  .m-b-15 {
    margin-bottom: 8px;
  }
}

@media screen and (max-width: 400px) {
  .m-tb-15 {
    margin-top: 7px;
    margin-bottom: 7px;
  }
  .m-t-15 {
    margin-top: 7px;
  }
  .m-b-15 {
    margin-bottom: 7px;
  }
}
