/*--------------------------------------------- FONTS ---------------------------------------------*/

/* https://google-webfonts-helper.herokuapp.com/fonts */
/* ZEILEN MIT "IE9 Compat Modes", "IE6-IE8" UND "Open Type Font" BITTE IMMER HÄNDISCH LÖSCHEN DA VERALTET */


/* --------------------------------------------- START Defaults START --------------------------------------------- */

body, html {
	height: 100%;
	min-height: 100%;
	font-size: 20px; /* Please modify Generic Font Size Responsive in last rows */
	line-height: 1.6rem;
	color: #E8E8E8;
}
body {
	font-size: 0.9rem;
	overflow-x: hidden;
	background-color: #ffffff;
	width: inherit;
	height: inherit;
}
body, table, input, select, textarea, pre {
	font-family: 'Fira Sans', sans-serif;
	font-weight: 400;
	color: #121212;
}

/* Table */

table {
	border: 0;
	border-collapse: collapse;
	width: 100%;
}
table caption {
	font-size: 1rem;
}
table colgroup col {
	width: 29.572650%;
}
table colgroup col:first-child {
	width: 5.470085%;
}
table thead {
	border-bottom: solid 2px #BFBFBF;
}
table thead th {
	padding-top: 6px;
	padding-bottom: 6px;
	text-align: left;
}
table tfoot td {
	border-top: solid 1px #BFBFBF;
	padding-top: 6px;
	padding-bottom: 6px;
}
table tbody td {
	border-left: 2px solid #FFFFFF;
	border-right: 2px solid #FFFFFF;
	padding-top: 6px;
	padding-bottom: 6px;
}
table tr * {
	padding-left: 1%;
	padding-right: 1%;
}

/* List */

ul li {
	left: 0;
	text-indent: -4px;
	padding-left: 20px;
	-webkit-column-break-inside: avoid;
	page-break-inside: avoid;
	break-inside: avoid;
}
ul li::before {
	content: '';
	position: relative;
	display: inline-block;
	background: #121212;
	height: 0.2rem;
	width: 0.2rem;
	/* -webkit-transform:rotate(45deg);
	transform:rotate(45deg); */
	top: -0.25rem;
	left: -16px;
}

/* Placeholder */

form input[type='text']::-webkit-input-placeholder,
form textarea::-webkit-input-placeholder {
	color: #E8E8E8;
	opacity: 1;
	font-family: 'Fira Sans', serif;
	font-size: 0.85rem;
	font-weight: 400;
	text-transform: inherit;
}
form input[type='text']:-moz-placeholder,
form textarea:-moz-placeholder {
	color: #E8E8E8;
	opacity: 1;
	font-family: 'Fira Sans', serif;
	font-size: 0.85rem;
	font-weight: 400;
	text-transform: inherit;
}
form input[type='text']::-moz-placeholder,
form textarea::-moz-placeholder {
	color: #E8E8E8;
	opacity: 1;
	font-family: 'Fira Sans', serif;
	font-size: 0.85rem;
	font-weight: 400;
	text-transform: inherit;
}
form input[type='text']:-ms-input-placeholder,
form textarea:-ms-input-placeholder {
	color: #E8E8E8;
	opacity: 1;
	font-family: 'Fira Sans', serif;
	font-size: 0.85rem;
	font-weight: 400;
	text-transform: inherit;
}

/* --------------------------------------------- END Defaults END --------------------------------------------- */

/* --------------------------------------------- START Spacings START --------------------------------------------- */

/* ------------------ CENTER ------------------ */

.center {
	position: relative;
	margin: 0 auto;
}
.centerSmall, .centerMedium, .centerLarge {
	position: relative;
	padding-left: 5%;
	padding-right: 5%;
	margin: 0 auto;
	width: 100%;
}
.centerSmall {
	max-width: calc(920px + 10%);
}
.centerMedium {
	max-width: calc(1156px + 10%);
}
.centerLarge {
	max-width: calc(1560px + 10%);
}

/* ------------------ Default Spacing ------------------ */

/* text-additional -> is only needed if you have in one module two types of texts, which have different styles */
.subtitle + .title,
.title + .subtitle {
	padding-top: 5px;
}
.title + .text,
.subtitle + .text {
	padding-top: 40px;
}
.text + .text-additional {
	padding-top: 50px;
}
.subtitle + .text-additional {
	padding-top: 50px;
}
.title + .buttons,
.text + .buttons,
.subtitle + .buttons {
	margin-top: 40px;
}
/* Following CSS is individual */
h3 + ul,
.t3 + ul,
.text-additional h3 + p,
.text-additional .t3 + p {
	padding-top: 10px;
}

/* --------------------------------------------- END Spacings END --------------------------------------------- */

/* -------------------------------------------------------------------------------------------------- */
/* --------------------------------------------- STYLEGUIDE --------------------------------------------- */
/* -------------------------------------------------------------------------------------------------- */

/* --------------------------------------------- START Typography START --------------------------------------------- */

.t1, .t1 a,
.t1 span, .t1 li {
	font-family: 'Playfair Display', sans-serif;
	font-size: 2.8rem;
	line-height: 2.8rem;
	color: #121212;
	font-weight: normal;
	text-transform: none;
	font-style: normal;
	letter-spacing: 0rem;
}
.t2, .t2 a,
.t2 span, .t2 li {
	font-family: 'Playfair Display', sans-serif;
	font-size: 2.4rem;
	line-height: 2.4rem;
	color: #121212;
	font-weight: normal;
	text-transform: none;
	font-style: normal;
	letter-spacing: 0rem;
}
.t3, .t3 a,
.t3 span, .t3 li {
	font-family: 'Playfair Display', sans-serif;
	font-size: 2rem;
	line-height: 2rem;
	color: #121212;
	font-weight: normal;
	text-transform: none;
	font-style: normal;
	letter-spacing: 0rem;
}
.t4, .t4 a,
.t4 span, .t4 li {
	font-family: 'Playfair Display', sans-serif;
	font-size: 1.6rem;
	line-height: 2rem;
	color: #121212;
	font-weight: normal;
	text-transform: none;
	font-style: normal;
	letter-spacing: 0rem;
}
.t5, .t5 a,
.t5 span, .t5 li {
	font-size: 0.7rem;
	line-height: 1.2rem;
	color: #121212;
	font-weight: normal;
	text-transform: uppercase;
	font-style: normal;
	letter-spacing: 0.1rem;
}
.t6, .t6 a,
.t6 span, .t6 li {
	font-size: 0.6rem;
	line-height: 1.2rem;
	color: #121212;
	font-weight: normal;
	text-transform: uppercase;
	font-style: normal;
	letter-spacing: 0.05rem;
}
.t7, .t7 a,
.t7 span, .t7 li {
	font-size: 0.6rem;
	line-height: 1.2rem;
	color: #121212;
	font-weight: normal;
	text-transform: uppercase;
	font-style: normal;
	letter-spacing: 0.05rem;
}
.font-large, .font-large a, .font-large p {
	font-size: 1.6rem;
	line-height: 2rem;
}
.font-medium, .font-medium a, .font-medium p {
	font-size: 1.2rem;
	line-height: 1.6rem;
}
.font-default, .font-default p, .font-default a, legend, label, select, input, textarea {
	font-size: 0.85rem;
}
.font-default, .font-default a, .font-default p {
	font-style: normal;
	font-weight: 400;
}
.font-small, .font-small a, .font-small p {
	font-size: 0.7rem;
	line-height: 1.2rem;
}
sub, sup {
	font-size: 50%;
	line-height: 100%;
	vertical-align: super;
}

/* --------------------------------------------- END Typography END --------------------------------------------- */

/* --------------------------------------------- START Swatches START --------------------------------------------- */

/* ------------------ BACKGROUND-COLORS ------------------ */

.b1 {
	background-color: #1D1D1B;
}
.b2 {
	background-color: #7E8388;
}
.b3 {
	background-color: #C10A27;
}
.b4 {
	background-color: #FFFFFF;
}
.b5 {
	background-color: #F3F3F3;
}

/* ------------------ COLORS ------------------ */

.c1,
.c1 a,
.c1 span {
	color: #1D1D1B;
}
.c2,
.c2 a,
.c2 span {
	color: #7E8388;
}
.c3,
.c3 a,
.c3 span {
	color: #C10A27;
}
/* negative color */
.c4,
.c4 a,
.c4 span {
	color: #FFFFFF;
}
.c5,
.c5 a,
.c5 span {
	color: #F3F3F3;
}
/* For a solid selection-color use an opacity of 0.99 instead of 1 */
::selection,
.text-selection {
	background: rgba(193, 10, 39, 0.99);
	color: #fff !important;
	-webkit-text-fill-color: #fff !important;
}
::-moz-selection {
	color: #ffffff !important;
	background: rgba(193, 10, 39, 0.99);
}
img::selection,
img::-moz-selection {
	background: transparent;
}

/* ------------------ SVG-Fill ------------------ */

.fill1 path, .fill2 path, 
.fill3 path, .fill4 path {
	-webkit-transition: fill 0.3s ease-in-out;
	-moz-transition: fill 0.3s ease-in-out;
	-ms-transition: fill 0.3s ease-in-out;
	-o-transition: fill 0.3s ease-in-out;
	transition: fill 0.3s ease-in-out;
}
.fill1:hover path {
	fill: #E8E8E8;
}
.fill2:hover path {
	fill: #c10a27;
}

/* --------------------------------------------- END Swatches END --------------------------------------------- */

/* --------------------------------------------- START Buttons START --------------------------------------------- */

/* ------------------ REGULAR ------------------ */

.button1, .button2, .button3,
.button4, .button5, .button6,
.button7 {
	position: relative;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	-webkit-transition: all 0.2s ease-in-out;
	-moz-transition: all 0.2s ease-in-out;
	-ms-transition: all 0.2s ease-in-out;
	-o-transition: all 0.2s ease-in-out;
	transition: all 0.2s ease-in-out;
}
.button1 {
	font-family: 'Fira Sans', sans-serif;
	color: #121212;
	padding-bottom: 15px;
	border-bottom: 2px solid rgba(32,32,32,0.15);
	font-weight: 600;
	font-size: 0.7rem;
	line-height: 1.2rem;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	font-style: normal;
	align-items: center;
}
.button1:hover,
.button1.small:hover {
	opacity: 1;
	border-color: #121212;
}
.button1.small {
	padding-bottom: 8px;
	font-size: 0.6rem;
	line-height: 1.2rem;
	border-bottom: 1px solid rgba(32,32,32,0.15);
}
.button2 {
	font-family: 'Fira Sans', sans-serif;
	color: #121212;
	padding: 16px 32px;
	border: 1px solid rgba(32,32,32,0.15);
	font-weight: 600;
	font-size: 0.7rem;
	line-height: 1.2rem;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	font-style: normal;
	align-items: center;
}
.button2:hover,
.button2.small:hover {
	opacity: 1;
	border-color: #121212;
}
.button2.small {
	padding: 8px 24px;
	font-size: 0.6rem;
	line-height: 1.2rem;
	border: 1px solid rgba(32,32,32,0.15);
}
.button3 {
	font-family: 'Fira Sans', sans-serif;
	color: #121212;
	font-weight: 600;
	font-size: 0.7rem;
	line-height: 1.2rem;
	letter-spacing: 0.1rem;
	text-transform: uppercase;
	font-style: normal;
	align-items: center;
}
.button3:hover {
	opacity: 0.3;
}

/* ------------------ NEGATIVE ------------------ */

.button1.negative {
	border-bottom: 2px solid rgba(255,255,255,0.15);
	color: #fff;
}
.button1.negative:hover {
	border-color: rgb(255, 255, 255);
}

.button2.negative {
	border: 1px solid rgba(255, 255, 255, 0.15);
	color: #fff;
}
.button2.negative:hover {
	border: 1px solid #fff;
}
.button3.negative {
	color: #fff;
}

/* --------------------------------------------- END Buttons END --------------------------------------------- */

/* --------------------------------------------- START Links START --------------------------------------------- */

/* ------------------ REGULAR ------------------ */

.link, .font-default a, a, p a, li a, span a,
legend a, label a, input a, select a {
	display: inline-block;
	font-weight: 600;
	color: #121212;
	text-decoration: underline;
	transition: all 0.2s ease-in-out;
}
.link:hover, .hover:hover, .font-default a:hover, a:hover, p a:hover, li a:hover, span a:hover,
legend a:hover, label a:hover, input a:hover, select a:hover {
	opacity: 0.3;
}

/* --------------------------------------------- END Links END --------------------------------------------- */

/* -------------------------------- SCROLLBAR ------------------------------------ */
body {
	overflow-y: overlay; /* overflow overlay: Behaves the same as auto, but with the scrollbars drawn on top of content instead of taking up space. Only supported in WebKit-based (e.g., Safari) and Blink-based (e.g., Chrome or Opera) browsers. */
}
/* for Firefox */
* {
	scrollbar-width: thin;
	scrollbar-color: rgba(0,0,0,.3) transparent; /* Scrollbar color, track color */
}
/* for Chrome, Safari, Opera et alii */
*::-webkit-scrollbar {
	width: calc(8/1920*100vw);
	min-width: 8px;
}
*::-webkit-scrollbar-track {
	background-color: transparent; /* Track color */
}
*::-webkit-scrollbar-thumb {
	border-radius: 8px;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	-ms-border-radius: 8px;
	-o-border-radius: 8px;
	background-color: rgba(0,0,0,0);
}
body.isScrolling::-webkit-scrollbar-thumb {
	background-color: rgba(0,0,0,0.4);
}
body::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0,0,0,0.2);
}
/*
/* SCROLLBAR NEGATIVE FOR DARK BACKGROUND */
/*
/* * {
	scrollbar-color: rgba(255,255,255,.6) transparent;
}
*::-webkit-scrollbar-thumb {
	background-color: rgba(255,255,255,.6);
}
*::-webkit-scrollbar-thumb:hover {
	background-color: rgba(255,255,255,.9);
} */
/* ------------------------------ SCROLLBAR END ---------------------------------- */


/* --------------------------------------------- START Responsive START --------------------------------------------- */

/* -------------------- BEGINN 750 BEGINN -------------------- */

@media screen and (max-width: 750px) {

	ul li {
		padding-left: 20px;
	}
	ul li::before {
		left: -15px;
	}
}

/* --------------------------------------------- START Generic Font Size Responsive START --------------------------------------------- */

@media screen and (max-width: 1200px) {
	body, html {
		font-size: 19px;
	}
}

@media screen and (max-width: 950px) {
	body, html {
		font-size: 18px;
	}
}

@media screen and (max-width: 700px) {
	body, html {
		font-size: 17px;
	}
}

@media screen and (max-width: 500px) {
	body, html {
		font-size: 16px;
	}
}

@media screen and (max-width: 400px) {
	body, html {
		font-size: 15px;
	}
}

/* --------------------------------------------- END Generic Font Size Responsive END --------------------------------------------- */

/* --------------------------------------------- END Responsive END --------------------------------------------- */