// ---------- CONFIGURATION ---------- //

$paddings: 200, 160, 120, 100, 80, 60, 40, 20;
$margins: 200, 160, 120, 100, 80, 60, 30, 15;
$breakpoints: 1200 .89, 950 .78, 700 .67, 500 .56, 400 .45;

// ------ END CONFIGURATIONS END ------ //

/*-------------- PADDINGS --------------*/
@each $padding in $paddings {
	.p-tb-#{$padding} {
		padding-top: #{$padding}px;
		padding-bottom: #{$padding}px;
	}
	.p-t-#{$padding} {
		padding-top: #{$padding}px;
	}
	.p-b-#{$padding} {
		padding-bottom: #{$padding}px;
	}
	@if $padding <= 100 {	
		.p-all-#{$padding} {
			padding: #{$padding}px;
		}
	}
	@each $breakpoint, $reduction in $breakpoints {
		@media screen and (max-width: #{$breakpoint}px) {
			.p-tb-#{$padding} {
				padding-top: #{round($padding * $reduction)}px;
				padding-bottom: #{round($padding * $reduction)}px;
			}
			.p-t-#{$padding} {
				padding-top: #{round($padding * $reduction)}px;
			}
			.p-b-#{$padding} {
				padding-bottom: #{round($padding * $reduction)}px;
			}
			// @if $padding < 100 {
			// 	.p-all-#{$padding} {
			// 		padding: #{round($padding * $reduction)}px;
			// 	}
			// }
		}
	}
}

/*-------------- MARGINS --------------*/
@each $margin in $margins {
	.m-tb-#{$margin} {
		margin-top: #{$margin}px;
		margin-bottom: #{$margin}px;
	}
	.m-t-#{$margin} {
		margin-top: #{$margin}px;
	}
	.m-b-#{$margin} {
		margin-bottom: #{$margin}px;
	}
	// @if $margin < 100 {
	// 	.m-all-#{$margin} {
	// 		margin: #{$margin}px;
	// 	}
	// 	.m-l-#{$margin} {
	// 		margin-left: #{$margin}px;
	// 	}
	// 	.m-r-#{$margin} {
	// 		margin-right: #{$margin}px;
	// 	}
	// }
	@each $breakpoint, $reduction in $breakpoints {
		@media screen and (max-width: #{$breakpoint}px) {
			.m-tb-#{$margin} {
				margin-top: #{round($margin * $reduction)}px;
				margin-bottom: #{round($margin * $reduction)}px;
			}
			.m-t-#{$margin} {
				margin-top: #{round($margin * $reduction)}px;
			}
			.m-b-#{$margin} {
				margin-bottom: #{round($margin * $reduction)}px;
			}
			// @if $margin < 100 {
			// 	.m-all-#{$margin} {
			// 		margin: #{round($margin * $reduction)}px;
			// 	}
			// 	.m-l-#{$margin} {
			// 		margin-left: #{round($margin * $reduction)}px;
			// 	}
			// 	.m-r-#{$margin} {
			// 		margin-right: #{round($margin * $reduction)}px;
			// 	}
			// }
		}
	}
}
