/* ------------------------------------------------------------------ */
/* -------------------------------- FONTS --------------------------- */
/* ------------------------------------------------------------------ */
@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@400;700&family=Parisienne&family=Thasadith&display=swap');
.serif {
	font-family: 'Amiri';
}
.sans-serif {
	font-family: 'Thasadith';
}
.handwrite {
	font-family: 'Parisienne';
}

/* ------------------------------------------------------------------ */
/* ------------------------------- CUSTOM --------------------------- */
/* ------------------------------------------------------------------ */
@media print {
	/* All your print styles go here */
	*:not(.print-content) { display: none !important; }
}
.spinner {
	animation-name: spin;
	animation-duration: 1000ms;
	animation-iteration-count: infinite;
	animation-timing-function: linear;
	position: fixed;
    top: calc(50vh - 1.5em);
    left: calc(50% - .5em);
    font-size: 48px;
}
@-ms-keyframes spin {
	from { -ms-transform: rotate(0deg); }
	to { -ms-transform: rotate(360deg); }
}
@-moz-keyframes spin {
	from { -moz-transform: rotate(0deg); }
	to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
	from {
		transform:rotate(0deg);
	}
	to {
		transform:rotate(360deg);
	}
}