.App {
	position: relative;
    height: 100vh;
	overflow-x: hidden;
	overflow-x: clip;
}

header nav.header {
	height: 42px;
	background: #666;
	width: 100%;
	margin: 0 auto;
	padding-left: 2.5%;
	padding-right: 2.5%;
	display: flex;
    align-items: center;
    position: fixed;
    top: 0;
	z-index: 10;
}
nav.header a {
	height: 42px;
}
nav.header svg {
	width: 32px;
    height: 19px;
}
nav.header .title {
	position: absolute;
    left: 50%;
    transform: translateX(-50%);
	color: white;
}

main {
	text-align: center;
	max-width: 800px;
    width: 95%;
    margin: 0 auto;
    position: relative;
	margin-top: 42px;
    padding-top: 5%;
}

/* -------------------------------- Login --------------------------- */
form.access {
	position: fixed;
    background: #333;
    padding: 5%;
    width: 100%;
    height: 100%;
}
form.access input[type="password"] {
	border-bottom: 1px solid white;
	margin-right: 12px;
	height: 32px;
	color: white;
}

/* HOME NAV */
nav.main .link {
	margin: 2%;
    width: 40vw;
	max-width: 120px;
    height: 40vw;
	max-height: 120px;
}
nav.main .circle {
	width: 55px;
	height: 55px;
	border-radius: 50%;
	box-shadow: 0px 1px 8px rgba(0,0,0,0.1);
}
nav.main li:nth-child(8n+1) .circle {
	background: rgba(183, 230, 243, 0.5);
}
nav.main li:nth-child(8n+2) .circle {
	background: rgba(252, 230, 211, 1);
}
nav.main li:nth-child(8n+3) .circle {
	background: rgba(255, 232, 234, 1);
}
nav.main li:nth-child(8n+4) .circle {
	background: rgba(255, 240, 190, 0.7);
}
nav.main li:nth-child(8n+5) .circle {
	background: rgba(111, 207, 151, .5);
}
nav.main li:nth-child(8n+6) .circle {
	background: #edece8;
}
nav.main li:nth-child(8n+7) .circle {
	background: #e2d4d4;
}
nav.main li:nth-child(8n+8) .circle {
	background: #adddcf;
}
nav.main svg {
	width: 25px;
    height: 25px;
}
nav.main .title {
	padding-top: calc(32/300*100%);
}

/* WELCOME LETTER */
.welcomeLetter .surname {
	text-transform: capitalize;
}
.welcomeLetter .pdf-wrapper {
	width: 100%;
	margin: 0 auto;
}
.welcomeLetter .pdf-wrapper .logo {
	width: 220px;
}
.welcomeLetter .pdf-wrapper p {
	padding-bottom: 18px;
}

/* -------------------------------- APP NOTIFICATIONS --------------------------- */
form.send-notification textarea {
	width: 100%;
	color: #333;
	border: 1px solid #999;
	margin-bottom: 24px;
}
.notifications .notification {
	padding-bottom: 24px;
	margin-bottom: 24px;
	text-align: left;
	border-bottom: 1px solid #999;
}

/* ------------------------------------------------------------------ */
/* -------------------------- GOURMET BOUTIQUE ---------------------- */
/* ------------------------------------------------------------------ */
/* BACKEND */
.product-nav button {
	margin: 5px;
}
.product-nav button.active, .purchase-filter button.active {
	background: #333;
	color: white;
}
.product-list, .stats, .bestseller, .revenue, .stock, .expiry, .product-detail {
	padding: 5%;
    background-color: white;
}
.product-list.backend .product {
    margin: 0 0 15px 0;
    width: 100%;
    height: auto;
    border: none;
}
.product-list.backend .product.inactive * {
    color: firebrick;
}
.product-list.backend .numb {
    width: 30px;
    display: inline-block;
    text-align: right;
    margin-right: 5px;
}
.product-detail.backend form {
    margin-top: 20px;
}
.product-detail.backend form > * {
    width: 50%;
    display: inline-block;
    padding: 5px;
}
.product-detail.backend form label {
    font-weight: 700;
    text-align: right;
}
.product-detail.backend form .submit {
    margin-top: 40px;
}
.purchases-list {
    padding: 5%;
    background-color: white;
}
.purchases-list .purchase-filter {
    padding-bottom: 32px;
}
.purchases-list .purchase-filter button {
    margin: 5px;
}
.purchases-list .purchase {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 2px solid #333;
}
.purchases-list .purchase .order {
    font-weight: bold;
}
.stats button {
    margin: 5px;
}