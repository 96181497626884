/* ------------------------------------------------ START Generic START ------------------------------------------------ */

@media all {

	html, body, div, span, applet, object, iframe,
	h1, h2, h3, h4, h5, h6, p, blockquote, pre,
	a, abbr, acronym, address, big, cite, code,
	del, dfn, em, img, ins, kbd, q, s, samp,
	small, strike, sub, sup, tt, var,
	b, u, i, center,
	dl, dt, dd, ol, ul, li,
	fieldset, form, label, legend,
	table, caption, tbody, tfoot, thead, tr, th, td,
	article, aside, canvas, details, embed, 
	figure, figcaption, footer, header, hgroup, 
	menu, nav, output, ruby, section, summary,
	time, mark, audio, video {
		margin: 0;
		padding: 0;
		border: 0;
		vertical-align: baseline;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	/* http://de.learnlayout.com/box-sizing.html */
	* {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	/* HTML5 display-role reset for older browsers */
	article, aside, details, figcaption, figure, 
	footer, header, hgroup, menu, nav, section {
		display: block;
	}
	footer {
		overflow-x: hidden;
	}
	ol, ul {
		list-style: none;
	}
	table {
		border-collapse: collapse;
		border-spacing: 0;
	}
	table, td, form {
		margin: 0;
		padding: 0;
	}
	address {
		font-style: normal;
	}
	h1, h2, h3, h4, h5, h6,
	h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
		margin: 0;
		padding: 0;
		text-decoration: none;
		letter-spacing: 0;
		font-weight: normal;
	}
	a, button, select, div, input, textarea, article, span {
		outline: none;
		text-decoration: none;
		background-color: transparent;
		border: 0;
	}
	input:focus,
	select:focus,
	textarea:focus,
	button:focus {
		outline: none;
	}
	a, button, select, div, input, textarea, article, span, ul ,li {
		outline: none !important;
		-webkit-tap-highlight-color: transparent;
	}
	img {
		max-width: 100%;
		height: auto;
		border: 0;
		vertical-align: middle;
	}
	video, svg {
		vertical-align: middle;
	}
	picture,
	picture a {
		display: block;
	}
	.owl-carousel {
		display: block;
	}
	form fieldset select {
		border-radius: 0;
	}
	button {
		padding: 0;
	}
	select {
		background-color: transparent;
	}
	/* remove the arrow from select element in ie11 */
	select::-ms-expand {
		display: none;
	}
	/* remove the blue highlighting from select element in ie11 */
	select::-ms-value {
		background: none;
		color: #3C3C3C;
	}
	/* for bookingsuedtirol calendar select */
	.Bs-Select {
		-webkit-appearance: menulist;
		-moz-appearance: menulist;
		-ms-appearance: menulist;
		-o-appearance: menulist;
		appearance: menulist;
	}
	.Bs-Select::-ms-expand {
		display: block;
	}
	.Bs-Checkbox input {
		width: 20px;
		height: auto;
	}
	.Bs-Guest__Terms .Bs-Checkbox input {
		position: relative;
		width: 13px;
		height: auto;
		opacity: 1;
	}
	/* WebKit browsers */
	form input[type='text']:focus::-webkit-input-placeholder,
	form textarea:focus::-webkit-input-placeholder {
		color: transparent;
		opacity: 0;
	}
	/* Mozilla Firefox 4 to 18 */
	form input[type='text']:focus:-moz-placeholder,
	form textarea:focus:-moz-placeholder {
		color: transparent;
		opacity: 0;
	}
	/* Mozilla Firefox 19+ */
	form input[type='text']:focus::-moz-placeholder,
	form textarea:focus::-moz-placeholder {
		color: transparent;
		opacity: 0;
	}
	/* Internet Explorer 10+ */
	form input[type='text']:focus:-ms-input-placeholder,
	form textarea:focus:-ms-input-placeholder {
		color: transparent;
		opacity: 0;
	}
	.lightbox-item {
		display: none;
	}
	.g-recaptcha {
		width: 100%;
		position: relative;
		-webkit-transform-origin: left top;
		transform-origin: left top;
	}

}

/* ------------------------------------------------ END Generic END ------------------------------------------------ */

/* ------------------------------------------------ START Presets START ------------------------------------------------ */

@media all {

	.object-fit {
		position: absolute;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
	}
	.coverImage {
		height: 0;
		position: relative;
		overflow: hidden;
	}
	.coverImage picture {
		height: 100%;
		width: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}
	.coverImage picture img {
		object-fit: cover;
		height: 100%;
		width: 100%;
	}
	.ttupper {
		text-transform: uppercase;
	}
	.ttlower {
		text-transform: lowercase;
	}
	.ttnone {
		text-transform: none;
	}
	.flex {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
	}
	.flex-center {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		justify-content: center;
	}
	.flex-column {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		flex-direction: column;
	}
	.flex-wrap {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: -webkit-flex;
		display: flex;
		flex-wrap: wrap;
	}
	.justify-center {
		justify-content: center;
	}
	.justify-end {
		justify-content: flex-end;
	}
	.space-between {
		justify-content: space-between;
	}
	.space-around {
		justify-content: space-around;
	}
	.align-start {
		align-items: flex-start;
	}
	.align-center {
		align-items: center;
	}
	.align-stretch {
		align-items: stretch;
	}
	.align-end {
		align-items: flex-end;
	}
	.block {
		display: block;
	}
	.inline-block {
		display: block;
	}
	.none {
		display: none;
	}
	.tac {
		text-align: center;
	}
	.tal {
		text-align: left;
	}
	.tar {
		text-align: right;
	}
	.noHover{
		pointer-events: none;
	}
	.bold {
		font-weight: 600;
	}
	.italic {
		font-style: italic;
	}

}

/* ------------------------------------------------ END Presets END ------------------------------------------------ */

/* --------------------------------------------- START Spacings START --------------------------------------------- */

/*-------------- WIDTH --------------*/

.width-100 {
	width: 100%;
}
.width-50 {
	width: 50%;
}
.width-25 {
	width: 25%;
}

/*-------------- PADDINGS --------------*/

/* for field 'space' in DCE - if no padding needed */
.nopadding {
	padding-top: 0 !important;
	padding-bottom: 0 !important;
}

/*-------------- MARGINS --------------*/

.m-all-auto {
	margin: auto;
}
.m-tb-auto {
	margin-top: auto;
	margin-bottom: auto;
}
.m-t-auto {
	margin-top: auto;
}
.m-b-auto {
	margin-bottom: auto;
}
.m-lr-auto {
	margin-left: auto;
	margin-right: auto;
}
.m-l-auto {
	margin-left: auto;
}
.m-r-auto {
	margin-right: auto;
}
/* for field 'space' in DCE - if no margin needed */
.nomargin {
	margin-top: 0 !important;
	margin-bottom: 0 !important;
}

/* --------------------------------------------- END Spacings END --------------------------------------------- */

/* ------------------------------------------------ START List/Table START ------------------------------------------------ */

@media all {

	/* List */

	ul {
		margin: 0;
		list-style-type: none;
	}
	.selectric-items ul li::before {
		display: none;
	}
	ul li ul {
		padding: 0;
	}
	ul li ul li {
		text-indent: 0;
		padding: 0;
	}
	ul li ul li::before {
		content: none;
	}
	ul li ul li::before {
		color: #6b6c6e;
	}
	ul.reset {
		padding: 0;
		text-indent: 0;
	}
	ul.reset li::before {
		content: none;
	}
	ul.reset li {
		position: static;
		left: auto;
		text-indent: 0;
		padding: 0;
	}
	ul.reset li ul {
		padding: 0;
	}
	ul.reset li ul li {
		padding: 0;
	}

	/* Table */

	table.reset {
		width: auto;
	}
	table.reset caption {
		font-size: inherit;
	}
	table.reset colgroup col {
		width: auto;
	}
	table.reset thead {
		border-bottom: inherit;
	}
	table.reset thead th {
		padding-top: inherit;
		padding-bottom: inherit;
		text-align: inherit;
	}
	table.reset tfoot td {
		border-top: inherit;
		padding-top: inherit;
		padding-bottom: inherit;
	}
	table.reset tbody td {
		border-top: inherit;
		padding-top: inherit;
		padding-bottom: inherit;
	}
	table.reset tr * {
		padding-left: inherit;
		padding-right: inherit;
	}

}

/* ------------------------------------------------ END List/Table END ------------------------------------------------ */